import {
  Box,
  Grid,
  Typography,
  Stack,
  Tooltip,
  Icon,
  Tab,
  Pagination,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import SpinningSpark from '../shared/components/SpinningSpark';
import videoReviewAPI from './videoReviewDashboard.api';
import LoggingService from '../shared/Logging.api';
import VideoReviewCard from './videoReviewCard';
import { useUser } from '../auth/User.context';
import { AlertsContext } from '../shared/alerts/Alerts.context';
import * as videoReviewService from './videoReview.service';
import colors from '../colors.styles';

export default function VideoReviewDashboard() {
  const {
    state: { user, displayName },
  } = useUser();
  const { timezone } = user;
  const { showAlert } = useContext(AlertsContext);
  const tabs = {
    toReview: 0,
    reviwed: 1,
  };
  const [selectedTab, setSelectedTab] = useState(tabs.toReview);
  const itemsPerPage = 10;
  // to review posts
  const [loadingPostToReview, setLoadingPostToReview] = useState(false);
  const [allPostsToReview, setAllPostsToReview] = useState([]);
  const [postToReview, setPostToReview] = useState([]); // posts to show in the current page
  const [toReviewPage, setToReviewPage] = useState(1);
  const [toReviewTotalPages, setToReviewTotalPages] = useState(0);
  const [enableScroll, setEnableScroll] = useState(true);
  // reviewed posts
  const [loadingReviewedPosts, setLoadingReviewedPosts] = useState(false);
  const [allReviewedPosts, setAllReviewedPosts] = useState([]);
  const [reviewedPosts, setReviewedPosts] = useState([]); // posts to show in the current page
  const [reviewedPage, setReviewedPage] = useState(1);
  const [reviewedTotalPages, setReviewedTotalPages] = useState(0);
  // Search posts
  const [searchQuery, setSearchQuery] = useState('');
  const [searchedReviewedPosts, setSearchedReviewedPosts] = useState([]);

  const zone = ` ${moment.tz(timezone).format('z')}`;
  const [refreshTime, setRefreshTime] = useState(
    moment.tz(timezone).format('h:mmA').concat(zone)
  );
  const [notifyRefresh, setNotifyRefresh] = useState(false);
  const [newPostTimer, setNewPostTimer] = useState(undefined);
  const [autoRefreshTimer, setAutoRefreshTimer] = useState(undefined);
  const reviewErrorText = {
    approval_expired:
      'Post has passed its publish time and cannot be approved.',
    rejection_expired:
      'Post has passed its publish time and cannot be rejected.',
    approval_generic:
      'Error approving the post — please refresh and try again.',
    rejection_generic:
      'Error rejecting the post — please refresh and try again.',
    already_reviewed: 'Error: Post has already been reviewed.',
  };

  const styles = {
    container: {
      marginBottom: '3vh',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    refreshButton: {
      padding: 1,
      margin: 1,
      minWidth: 'auto',
      borderRadius: '50%',
    },
    refreshNotification: {
      color: 'white',
      background: colors.base.walmart_blue,
      position: 'relative',
      display: 'inline-block',
      padding: '4px 12px',
      borderRadius: '50px',
      boxShadow: `1px 2px 0px 1px ${colors.base.spark_yellow}`,
      '&:hover': {
        backgroundColor: colors.base.walmart_blue,
      },
    },
    tabList: {
      '.MuiTabs-flexContainer': {
        borderBottom: `2px solid ${colors.base.light_gray}`,
      },
      '.MuiTabs-indicator': {
        backgroundColor: colors.base.spark_yellow,
      },
      '.MuiButtonBase-root': {
        textTransform: 'capitalize',
        fontSize: '1rem',
        backgroundColor: colors.base.almost_white_blue,
        borderRadius: !selectedTab ? '0 10% 0 0' : '10px 0 0 0',
      },
      '.Mui-selected': {
        color: `${colors.base.light} !important`,
        backgroundColor: colors.base.walmart_blue,
        borderRadius: selectedTab ? '0 10% 0 0' : '10px 0 0 0',
      },
    },
    searchInput: {
      width: '320px',
      height: '48px',
      backgroundColor: '#F5F7F9',
      borderRadius: '8px',
      '& .MuiOutlinedInput-root': {
        height: '48px',
        padding: '0 12px',
        '& fieldset': {
          borderColor: '#F5F7F9',
        },
        '&:hover fieldset': {
          borderColor: '#F5F7F9',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#F5F7F9',
        },
      },
      '& .MuiInputBase-input': {
        padding: '12px 0',
        boxSizing: 'border-box',
        height: '100%',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#AEBDCC',
        fontWeight: 500,
      },
    },
  };

  const fetchPostsToReview = () => {
    // Clears the refresh timer if there is one
    clearTimeout(autoRefreshTimer);
    setLoadingPostToReview(true);
    videoReviewAPI
      .getVideosToReview()
      .then((response) => {
        const filteredPosts = videoReviewService.filterPosts(
          response.data,
          user.timezone
        );
        setAllPostsToReview(filteredPosts);
        setToReviewTotalPages(Math.ceil(filteredPosts.length / itemsPerPage));
        setPostToReview(
          filteredPosts.slice(
            (toReviewPage - 1) * itemsPerPage,
            toReviewPage * itemsPerPage
          )
        );
        setRefreshTime(moment.tz(timezone).format('h:mmA').concat(zone));
        determineRefreshTimer(filteredPosts);
      })
      .catch((error) => {
        LoggingService.error('Error getting posts to review', error);
        showAlert('Error getting posts to review', 'error');
      })
      .finally(() => setLoadingPostToReview(false));
  };

  const fetchReviewedPosts = () => {
    // Clears the refresh timer if there is one
    clearTimeout(autoRefreshTimer);
    setLoadingReviewedPosts(true);
    videoReviewAPI
      .getReviewedVideos()
      .then((response) => {
        let formattedReviewedPosts =
          videoReviewService.formatToUserTimezoneAndSortPosts(
            response.data,
            user.timezone
          );
        // reverse the order so the most chronologically recent is on top, and get older as it goes down
        formattedReviewedPosts = formattedReviewedPosts.reverse();
        setAllReviewedPosts(formattedReviewedPosts);
        setSearchedReviewedPosts(formattedReviewedPosts);
        setReviewedTotalPages(
          Math.ceil(formattedReviewedPosts.length / itemsPerPage)
        );
        setReviewedPosts(
          formattedReviewedPosts.slice(
            (reviewedPage - 1) * itemsPerPage,
            reviewedPage * itemsPerPage
          )
        );
        setRefreshTime(moment.tz(timezone).format('h:mmA').concat(zone));
      })
      .catch((error) => {
        LoggingService.error('Error getting reviewed posts', error);
        showAlert('Error getting reviewed posts', 'error');
      })
      .finally(() => setLoadingReviewedPosts(false));
  };

  // eslint-disable-next-line no-shadow
  const determineRefreshTimer = (postsList) => {
    // Auto refresh after a minute if there are no posts to review
    if (postsList.length === 0) {
      setAutoRefreshTimer(
        setTimeout(() => {
          fetchPostsToReview();
        }, 60000)
      );
    }
  };

  const checkForNewPosts = () => {
    setNewPostTimer(
      setTimeout(() => {
        videoReviewAPI.getVideosToReview().then((response) => {
          let checkAgain = true;
          const newFilteredPosts = videoReviewService.filterPosts(
            response.data,
            user.timezone
          );
          // check to see if there are any new posts that aren't currently in our post list
          for (
            let newPostIndex = 0;
            newPostIndex < newFilteredPosts.length;
            newPostIndex += 1
          ) {
            if (
              !allPostsToReview.some(
                (post) => post.id === newFilteredPosts[newPostIndex].id
              )
            ) {
              setNotifyRefresh(true);
              checkAgain = false;
              break;
            }
          }
          if (checkAgain) {
            checkForNewPosts();
          }
        });
      }, 60000)
    );
  };

  const onTabChange = () => {
    clearTimeout(newPostTimer);
    setNotifyRefresh(false);
    if (selectedTab === tabs.toReview) {
      fetchPostsToReview();
      setToReviewPage(1);
    } else {
      fetchReviewedPosts();
      setReviewedPage(1);
    }
  };

  // fetch posts depending on the selected tab
  useEffect(() => {
    onTabChange();
  }, [selectedTab]);

  // sets off the new post check
  useEffect(() => {
    clearTimeout(newPostTimer);
    if (!notifyRefresh && allPostsToReview.length > 0) {
      checkForNewPosts();
    }
  }, [allPostsToReview]);

  // these 2 useEffects below update the posts to review and
  // reviewed posts when the page changes
  useEffect(() => {
    setPostToReview(
      allPostsToReview.slice(
        (toReviewPage - 1) * itemsPerPage,
        toReviewPage * itemsPerPage
      )
    );
    if (enableScroll) {
      // snap user to top of page when changing pages
      window.scrollTo(0, 0);
    }
    setEnableScroll(true);
  }, [toReviewPage, allPostsToReview]);

  useEffect(() => {
    setReviewedPosts(
      searchedReviewedPosts.slice(
        (reviewedPage - 1) * itemsPerPage,
        reviewedPage * itemsPerPage
      )
    );
    // snap user to top of page when changing pages
    window.scrollTo(0, 0);
  }, [reviewedPage, allReviewedPosts]);

  const handlePostError = (error, action) => {
    const errorData = error?.response?.data?.data;
    switch (errorData) {
      case 'Post is not eligible for review':
        showAlert(
          action === 'approve'
            ? reviewErrorText.approval_expired
            : reviewErrorText.rejection_expired,
          'error'
        );
        break;
      case reviewErrorText.already_reviewed:
        showAlert(reviewErrorText.already_reviewed, 'error');
        break;
      default:
        showAlert(
          action === 'approve'
            ? reviewErrorText.approval_generic
            : reviewErrorText.rejection_generic,
          'error'
        );
        break;
    }
  };

  const approvePost = (postId, publish_time, setLoading) => {
    if (videoReviewService.isPostExpired(publish_time)) {
      showAlert(reviewErrorText.approval_expired, 'error');
      fetchPostsToReview();
      setRefreshTime(moment.tz(timezone).format('h:mmA').concat(zone));
      refilterPosts();
      return;
    }
    videoReviewAPI
      .approvePost(postId, {
        reviewedByWalmartId: user.userId,
        reviewedBy: displayName,
        action: 'approved',
      })
      .then((response) => {
        const filteredPosts = allPostsToReview.filter(
          (post) => post.id !== response.data.id
        );
        setAllPostsToReview(filteredPosts);
        determineRefreshTimer(filteredPosts);
      })
      .catch((error) => {
        LoggingService.error(`Error approving post`, error);
        handlePostError(error, 'approve');
        refilterPosts();
      })
      .finally(() => setLoading(false));
  };

  const rejectPost = (postId, publish_time, setLoading) => {
    if (videoReviewService.isPostExpired(publish_time)) {
      showAlert(reviewErrorText.rejection_expired, 'error');
      refilterPosts();
      return;
    }
    videoReviewAPI
      .rejectPost(postId, {
        reviewedByWalmartId: user.userId,
        reviewedBy: displayName,
        action: 'rejected',
      })
      .then((response) => {
        const filteredPosts = allPostsToReview.filter(
          (post) => post.id !== response.data.id
        );
        setAllPostsToReview(filteredPosts);
        determineRefreshTimer(filteredPosts);
      })
      .catch((error) => {
        LoggingService.error(`Error rejecting post`, error);
        handlePostError(error, 'reject');
        refilterPosts();
      })
      .finally(() => setLoading(false));
  };

  // Refilter the posts to remove any that have expired
  const refilterPosts = () => {
    const filteredPosts = videoReviewService.filterPosts(
      allPostsToReview,
      user.timezone
    );
    setAllPostsToReview(filteredPosts);
    determineRefreshTimer(filteredPosts);
  };

  // Search logic
  function normalizeString(str) {
    return str
      .toLowerCase()
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, ''); // remove all previously split accents
  }

  function searchForPosts(posts, query) {
    const normalizedQuery = normalizeString(query);

    return posts.filter((post) => {
      const reviewedBy = normalizeString(
        _.get(post, 'reviewAction.reviewed_by', '')
      );
      const reviewedByUserId = normalizeString(
        _.get(post, 'reviewAction.reviewed_by_walmart_id', '')
      );
      const createdBy = normalizeString(_.get(post, 'created_by', ''));
      const createdByUserId = normalizeString(
        _.get(post, 'created_by_user_id', null)
          ? Object.values(post.created_by_user_id).find(
              (id) => id !== 'NOT_FOUND'
            )
          : ''
      );
      const searchableString = `${createdBy} ${createdByUserId} ${reviewedBy} ${reviewedByUserId}`;

      return searchableString.includes(normalizedQuery);
    });
  }

  // Reload the posts when the search query changes
  useEffect(() => {
    // Reset the page to 1 when the search query changes
    setReviewedPage(1);

    const result = searchForPosts(allReviewedPosts, searchQuery);
    setSearchedReviewedPosts(result);
    setReviewedPosts(
      result.slice(
        (reviewedPage - 1) * itemsPerPage,
        reviewedPage * itemsPerPage
      )
    );
    setReviewedTotalPages(Math.ceil(result.length / itemsPerPage));
  }, [searchQuery]);

  const SearchInput = (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Stack
          spacing={6}
          alignItems="flex-end"
          width="100%"
          marginBottom={2.5}
        >
          <TextField
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            placeholder="Search posts by name or user ID"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery ? (
                    <CloseIcon
                      style={{ cursor: 'pointer', color: '#AEBDCC' }}
                      onClick={() => setSearchQuery('')}
                    />
                  ) : (
                    <SearchIcon style={{ color: '#AEBDCC' }} />
                  )}
                </InputAdornment>
              ),
            }}
            sx={styles.searchInput}
          />
        </Stack>
      </Grid>
    </Grid>
  );

  const renderPosts = (posts, allowActions, totalPages, page, setPage) => {
    if (posts.length > 0) {
      return (
        <Stack spacing={{ xs: 1, sm: 2, md: 4 }}>
          {posts.map((post) => (
            <VideoReviewCard
              post={post}
              allowActions={allowActions}
              approvePost={approvePost}
              rejectPost={rejectPost}
              key={post.id}
            />
          ))}
          <Box display="flex" justifyContent="center" paddingY="20px">
            <Pagination
              count={totalPages}
              page={page}
              onChange={(temp, value) => setPage(value)}
              color="primary"
            />
          </Box>
        </Stack>
      );
    }
    return (
      <Typography variant="h6" align="center" sx={{ marginTop: '20vh' }}>
        No posts {allowActions ? 'eligible for review' : 'to show'}
      </Typography>
    );
  };

  return (
    <Box sx={styles.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ marginBottom: '3vh' }}
      >
        <Typography variant="h4" sx={{ marginY: 'auto' }}>
          Video Review{' '}
          <Tooltip
            title="Video Review shows videos that have been scheduled in MLS. Some videos will be automatically reviewed. 
                          Remaining videos will need to be manually reviewed. 
                          Videos that are not reviewed in time will expire and not be published."
          >
            <Icon>
              <HelpOutlineIcon />
            </Icon>
          </Tooltip>
        </Typography>
        <Typography variant="body2" sx={{ marginY: 'auto' }}>
          {notifyRefresh && (
            <Button
              sx={styles.refreshNotification}
              onClick={() => onTabChange()}
            >
              Refresh for New Posts
            </Button>
          )}
          <Tooltip title="Refresh Post List.">
            <Button
              size="small"
              sx={styles.refreshButton}
              onClick={() => onTabChange()}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
          Last Refreshed: {refreshTime}
        </Typography>
      </Box>
      <Grid>
        <TabContext value={selectedTab}>
          <TabList
            onChange={(temp, newValue) => setSelectedTab(newValue)}
            sx={styles.tabList}
          >
            <Tab
              label={`Unreviewed ${
                allPostsToReview.length > 0
                  ? `(${allPostsToReview.length})`
                  : ''
              }`}
              value={tabs.toReview}
            />
            <Tab label="Reviewed" value={tabs.reviwed} />
          </TabList>
          <TabPanel value={tabs.toReview} sx={{ padding: '24px 0' }}>
            {loadingPostToReview ? (
              <SpinningSparkBox />
            ) : (
              renderPosts(
                postToReview,
                true,
                toReviewTotalPages,
                toReviewPage,
                setToReviewPage
              )
            )}
          </TabPanel>
          <TabPanel value={tabs.reviwed} sx={{ padding: '24px 0' }}>
            {loadingReviewedPosts ? (
              <SpinningSparkBox />
            ) : (
              <>
                {SearchInput}
                {renderPosts(
                  reviewedPosts,
                  false,
                  reviewedTotalPages,
                  reviewedPage,
                  setReviewedPage
                )}
              </>
            )}
          </TabPanel>
        </TabContext>
      </Grid>
    </Box>
  );
}

function SpinningSparkBox() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
    >
      <SpinningSpark />
    </Box>
  );
}
