import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import {
  TokenType,
  BackgroundType,
  LayoutType,
  DisplayOption,
} from 'powerbi-models';
import './PowerBI.css';
import powerbiApi from './PowerBI.api';
import SpinningSpark from '../shared/components/SpinningSpark';

export default function PowerBI() {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(null);

  useEffect(() => {
    setLoading(true);
    powerbiApi
      .getPowerBICredentials()
      .then((response) => setCredentials(response.data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const embedConfig = {
    type: 'report',
    tokenType: TokenType.Embed,
    accessToken: credentials?.embedToken,
    embedUrl: credentials?.url,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: BackgroundType.Transparent,
      layoutType: LayoutType.Custom,
      customLayout: {
        displayOption: DisplayOption.FitToWidth,
      },
      navContentPaneEnabled: false,
      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: false,
            },
            selector: {
              $schema: "http://powerbi.com/product/schema#visualSelector",
              visualName:"b9cb3bd001ed9b306d32", 
            },
          },
          {
            settings: {
              visible: false,
            },
            selector: {
              $schema: "http://powerbi.com/product/schema#visualSelector",
              visualName:"bc8b02ff02293010dc26", 
            },
          }
        ]
      },
    },
  };

  const onReportLoaded = async (report) => {
    try {
      report.on("loaded", async () => {
        try {
          console.log("Report loaded successfully:", report);
  
          console.log("Attempting to get pages");
          const pages = await report.getPages();

          console.log("Pages:", pages);
  
          if (pages.length === 0) {
            console.warn("No pages found in the report");
            return;
          }
  
          const firstPage = pages[0];
          console.log("First page:", firstPage);

          console.log("Attempting to get visuals from first page");
          const visuals = await firstPage.getVisuals();
  
          console.log("Visuals:", visuals);
  
          visuals.forEach((visual) => {
            console.log("Visual Title:", visual.title);
            console.log("Visual Name:", visual.name); 
          });
        } catch (error) {
          console.error("Error fetching pages or visuals after loading:", error);
        }
      });
  
      report.on("error", (event) => {
        console.error("Error occurred while loading the report:", event.detail);
      });
  
    } catch (error) {
      console.error("Error in report loading process:", error);
    }
  };
  
  

  return (
    <Box marginBottom="3vh">
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <SpinningSpark />
        </Box>
      ) : (
        <PowerBIEmbed
          embedConfig={embedConfig}
          cssClassName="report-container"
          getEmbeddedComponent={(embeddedReport) => onReportLoaded(embeddedReport)} 
        />
      )}
    </Box>
  );
}
